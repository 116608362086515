var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        scopedSlots: _vm._u([
          {
            key: "datePicker",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ])
      }),
      _c("bookings-list", {
        attrs: { searchResult: _vm.bookings_SearchResult }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }